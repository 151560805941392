/* TODO - Tailwind is breaking login screen css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body,
  body > div:first-child,
  div#__next,
  div#__next > div {
    height: 100%;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button,
[type="submit"] {
  -webkit-appearance: button;

  background-image: none;
}
iframe {
  border-radius: 10px;
}
Vimeo {
  border-radius: 10px;
}
@media (max-width: 640px) {
  iframe {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  @media (max-width: 760px) {
    iframe {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
}
