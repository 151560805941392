/* App.css */
#root {
  text-align: center;
  margin: 1.2rem;
}

nav {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.auth-wrapper button {
  background-color: #028391;

  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.auth-wrapper button:hover {
  background-color: #12a1b1;
  color: white;
  cursor: pointer;
}
